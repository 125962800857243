import { Controller } from "@hotwired/stimulus"
import { ChoroplethChart } from 'chartjs-chart-geo'
import { feature } from 'topojson-client'

// Connects to data-controller="choropleth-chart"
export default class extends Controller {

  static targets = ['canvas']
  static values = {
    type: { type: String, default: 'line' },
    data: Array,
    options: Object
  }

  connect() {
    this.initializeMap()
  }

  disconnect() {
    this.chart.destroy()
    this.chart = undefined
  }

  async initializeMap() {
    const response = await fetch('https://unpkg.com/us-atlas/states-10m.json')
    const us = await response.json()
    this.nation = feature(us, us.objects.nation).features[0]
    this.states = feature(us, us.objects.states).features

    const element = this.hasCanvasTarget ? this.canvasTarget : this.element

    this.chart = new ChoroplethChart(element.getContext('2d'), {
        data: {
          labels: this.states.map((d) => d.properties.name),
          datasets: [{
            label: 'States',
            outline: this.nation,
            backgroundColor: (context) => {
              const maxValue = Math.max(...context.dataset.data.map(state => state.value))

              if (context.dataIndex == null) {
                return null
              }
              const value = context.dataset.data[context.dataIndex]
              return `rgba(67, 56, 202, ${parseFloat(value.value / maxValue)} )`
            },
            data: this.states.map((d) => ({
              feature: d,
              value: this.getValueFromData(d)
            }))
          }]
       },
       options: {
        showOutline: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
          tooltip: {
            callbacks: {
              label: (item) => {
                if (item.formattedValue == null || isNaN(item.formattedValue)) {
                  return `${item.chart.data?.labels?.[item.dataIndex]}: 0%`;
                }

                return `${item.chart.data?.labels?.[item.dataIndex]}: ${item.formattedValue}%`
              }
            }
          }
        },
        scales: {
          color: {
            fullSize: true,
            ticks: {
              callback: (tick) => {
                return `${tick}%`
              }
            },
            legend: {
              display: true,
              length: 0.9,
              align: 'right',
              position: 'bottom-left',
            },
            display: true,
            interpolate: (value) => {
              return `rgba(67, 56, 202, ${parseFloat(value)} )`
            }
          },
        }
      }
    })
  }

  getValueFromData(d) {
    const selectedState = this.dataValue.filter((value) => d.properties.name == value.state)[0]

    if (selectedState === undefined || selectedState === null) {
      return null
    } else {
      return parseFloat(selectedState.value)
    }
  }
}
