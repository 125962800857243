import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="section-toggler"
export default class extends Controller {
  static classes = ["hidden", "visible"]
  static targets = ["section"]

  toggleTargetSection({ params: { targetSectionId } }) {
    this.hideAllSections()
    document.getElementById(targetSectionId).classList.remove(...this.hiddenClasses)
    document.getElementById(targetSectionId).classList.add(...this.visibleClasses)
  }

  hideAllSections() {
    this.sectionTargets.forEach(section => this.hideSection(section))
  }

  hideSection(section) {
    section.classList.remove(...this.visibleClasses)
    section.classList.add(...this.hiddenClasses)
  }
}
