import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="url-validator"
export default class extends Controller {
  static classes = [ "base", "valid", "error" ]

  validateUrl(event) {
    if(this.element.value.length < 3){
      return
    }

    if(/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(this.element.value)) {
      this.element.classList.remove(...this.baseClasses)
      this.element.classList.remove(...this.errorClasses)
      this.element.classList.add(...this.validClasses)
    } else {
      this.element.classList.remove(...this.baseClasses)
      this.element.classList.remove(...this.validClasses)
      this.element.classList.add(...this.errorClasses)
    }
  }
}
