import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pop-up-opener"
export default class extends Controller {
  static values = {
    left: { type: Number, default: 5 },
    top: { type: Number, default: 5 },
    width: { type: Number, default: window.screen.availWidth / 2 },
    height: { type: Number, default: window.screen.availHeight / 2 },
    url: String
  }

  open() {
     window.open(
      this.urlValue,
      'AirbnbPopup',
      `width=${this.widthValue},height=${this.heightValue},left=${this.leftValue},top=${this.topValue}`
    )
  }
}
