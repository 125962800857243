import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"
import 'flatpickr/dist/flatpickr.css'
// Connects to data-controller="datepicker"
export default class extends Controller {
  static targets = ['input']
  static classes = ['open']
  static values = {
    mode: { type: String, default: 'range' },
    enableTime: { type: Boolean, default: false  },
    noCalendar: { type: Boolean, default: false  },
    dateFormat: { type: String, default: 'Y-m-d' }
  }

  connect() {
    if (!this.flatpickr) {
      this.flatpickr = flatpickr(this.inputTarget, {
        mode: this.modeValue,
        enableTime: this.enableTimeValue,
        noCalendar: this.noCalendarValue,
        dateFormat: this.dateFormatValue,
        onOpen: () => this.element.classList.add(...this.openClasses),
        onClose: () => this.element.classList.remove(...this.openClasses)
      })
    }
  }

  toggle() {
    this.flatpickr.toggle()
  }
}
