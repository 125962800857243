import { Controller } from '@hotwired/stimulus'
import Chart from 'chart.js/auto'
import 'chartjs-adapter-date-fns'

export default class extends Controller {

  static targets = ['canvas']
  static values = {
    type: { type: String, default: 'line' },
    tooltipWithPercentage: { type: Boolean, default: false },
    data: Object,
    options: Object
  }

  connect() {
    const element = this.hasCanvasTarget ? this.canvasTarget : this.element

    this.chart = new Chart(element.getContext('2d'), {
      type: this.typeValue === 'histogram' ? 'bar' : this.typeValue,
      data: this.dataValue,
      options: this.optionsWithDefaults()
    })
  }

  disconnect() {
    this.chart.destroy()
    this.chart = undefined
  }

  optionsWithDefaults() {
    if (this.tooltipWithPercentageValue === true) {
      const { plugins: pluginsOptions, ...otherOptions } = this.optionsValue
      const { tooltip: tooltipOptions, ...otherPlugins } = pluginsOptions
      return {
        plugins: {
          ...otherPlugins,
          tooltip: {
            ...tooltipOptions,
            callbacks: {
              label: ({ label, parsed: value, chart: { config: { data: { datasets: [ { data }] } } } } ) => {
                const totalSum =  data.reduce((partialSum, element) => partialSum + element, 0)
                const percentageValue = ((value / totalSum) * 100).toFixed(1)
                return `${label}: ${percentageValue}% (${value})`
              }
            }
          }
        },
        ...otherOptions
      }
    } else if (this.typeValue === 'histogram') {
      const { plugins: pluginsOptions, ...otherOptions } = this.optionsValue
      const { tooltip: tooltipOptions, ...otherPlugins } = pluginsOptions
      return {
        plugins: {
          ...otherPlugins,
          tooltip: {
            ...tooltipOptions,
            callbacks: {
              title: (items) => {
                if (!items.length) {
                  return ''
                }

                const item = items[0]
                const min = parseFloat(item.raw.min)
                const max = parseFloat(item.raw.max)
                const label = item.dataset.labelX

                return `${label}: ${min} - ${max}`
              }
            }
          }
        },
        ...otherOptions
      }
    }
    return this.optionsValue
  }
}
