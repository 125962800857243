import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggle-section"
export default class extends Controller {
  static values = { objective: String, togglerCondition: String, showOnMatch: { type: Boolean, default: true } }

  connect() {
    this.toggle()
  }

  toggle() {
    if (this.hasObjectiveValue && this.togglerConditionValue.split(",").includes(this.element.value) ) {
      this.showOnMatchValue ? document.querySelector(this.objectiveValue).classList.remove('hidden') : document.querySelector(this.objectiveValue).classList.add('hidden')
    } else {
      this.showOnMatchValue ? document.querySelector(this.objectiveValue).classList.add('hidden') : document.querySelector(this.objectiveValue).classList.remove('hidden')
    }
  }
}
