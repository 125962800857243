import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="rating"
export default class extends Controller {
  static targets = ['star', 'input']
  static values = { outlineStyle: String, solidStyle: String }

  rate({ params: { rate } }) {
    this.inputTarget.value = rate

    this.starTargets.forEach((starElement, index) => {
      if(index < rate) {
        starElement.setAttribute('style', this.solidStyleValue)
      } else {
        starElement.setAttribute('style', this.outlineStyleValue)
      }
    })
  }
}
