import { Controller } from "@hotwired/stimulus"
import tippy, { createSingleton } from 'tippy.js'

window.tippySingleton = {
  instances: [],
  instance: null,

  update() {
    if (this.instance) {
      this.instance.destroy()
    }
    if (this.instances.length > 0) {
      this.instance = createSingleton(this.instances, {
        allowHTML: true,
        interactive: true,
        followCursor: true,
        appendTo: () => document.body,
        moveTransition: 'transform 0.2s ease-out',
        overrides: ['placement', 'trigger'],
      })
    }
  },

  register(tippyInstance) {
    this.instances.push(tippyInstance)
    this.update()
  },

  unregister(tippyInstance) {
    this.instances = this.instances.filter(instance => instance !== tippyInstance)
    this.update()
  }
}

// Connects to data-controller="tippy"
export default class extends Controller {
  static values = {
    content: { type: String,
               default: `<div class="p-6 pointer text-xs flex place-items-center leading-5 font-semibold rounded-lg bg-white text-gray-800 min-h-[100px] min-w-[100px] shadow-2xl">
                          Loading...
                         </div>`},
    trigger: { type: String, default: 'click' },
    addOnShowCallback: { type: Boolean, default: true },
    placement: { type: String, default: 'top-start' },
    singleton: { type: Boolean, default: false }
  }

  connect() {
    this.tippy = tippy(this.element, {
      content: this.contentValue,
      allowHTML: true,
      interactive: true,
      arrow: this.arrowValue,
      placement: this.placementValue,
      trigger: this.triggerValue,
      appendTo: () => document.body,

      onShow(instance) {
        const tippyController = Stimulus.getControllerForElementAndIdentifier(instance.reference, "tippy")
        if(tippyController.addOnShowCallbackValue) {
          fetch(instance.reference.dataset.tippyContentUrl)
            .then((response) => response.text())
            .then(html => instance.setContent(html))
            .catch((error) => instance.setContent(`<div class="p-6 pointer text-xs flex place-items-center leading-5 font-semibold rounded-lg bg-white text-gray-800 min-h-[100px] min-w-[100px] shadow-2xl">
                                                    Request failed. ${error}
                                                   </div>`))
        }
      },
      onHidden(instance) {
        const tippyController = Stimulus.getControllerForElementAndIdentifier(instance.reference, "tippy")
        if(tippyController.addOnShowCallbackValue) {
          instance.setContent(
            `<div class="p-6 pointer text-xs flex place-items-center leading-5 font-semibold rounded-lg bg-white text-gray-800 min-h-[100px] min-w-[100px] shadow-2xl">
              Loading...
             </div>`)
        }
      }
    })

    if (this.singletonValue) {
      window.tippySingleton.register(this.tippy)
    }
  }

  disconnect() {
    if (this.tippy) {
      this.tippy.unmount()
      if (this.singletonValue) {
        window.tippySingleton.unregister(this.tippy)
      }
    }
  }
}
