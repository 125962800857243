import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="truncate-text"
export default class extends Controller {
  static values = {
    replaceText: String,
  }

  static targets = ['textBox', 'seeMoreBtn', 'seeLessBtn']

  changeText() {
    const newText = this.replaceTextValue
    this.replaceTextValue = this.textBoxTarget.innerHTML
    this.textBoxTarget.innerHTML = newText
    this.toggleButtons()
  }

  toggleButtons() {
    this.seeMoreBtnTarget.classList.toggle('block')
    this.seeMoreBtnTarget.classList.toggle('hidden')
    this.seeLessBtnTarget.classList.toggle('hidden')
    this.seeLessBtnTarget.classList.toggle('block')
  }
}
