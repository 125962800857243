import { Controller } from "@hotwired/stimulus"
import { WordCloudChart } from 'chartjs-chart-wordcloud';

// Connects to data-controller="word-cloud"
export default class extends Controller {
  static targets = ['canvas']
  static values = {
    data: Object,
    options: Object
  }

  connect() {
    const element = this.hasCanvasTarget ? this.canvasTarget : this.element

    this.chart = new WordCloudChart(element.getContext('2d'), {
      data: this.dataValue,
      options: this.optionsValue
    })
  }

  disconnect() {
    this.chart.destroy()
    this.chart = undefined
  }
}
