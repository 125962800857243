import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="image-preview"
export default class extends Controller {
  static targets = ['preview', 'fileInput', 'template']

  showPreview() {
    const [file] = this.fileInputTarget.files

    if (file) {
      const content = this.templateTarget.innerHTML.replace(/PREVIEW_SRC/g,  URL.createObjectURL(file))
      this.previewTarget.outerHTML = content
    }
  }
}
