import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reva-extension-checker"
export default class extends Controller {
  static values = { redirectPath: String }

  checkIfInstalled() {
    if (!document.documentElement.dataset.revaExtensionInstalled) {
      this.element.href = this.redirectPathValue
    }
  }
}
