import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="empty-field-checker"
export default class extends Controller {
  static targets = [ "field" ]

  initialize() {
    this.initialFieldsValues = {}
  }

  connect() {
    if (this.hasFieldTarget) {
      this.fieldTargets.forEach((target) => {
        this.initialFieldsValues[target.id] = target.value
      })
    }
  }

  checkIfWillEmptyFields(event) {
    const isEmptyingFields = this.fieldTargets.some((target) => target.value == "" && this.initialFieldsValues[target.id] !== "")
    const confirmCopy = "You are about to delete an existing listing link, this will delete all existing reviews for such listing.\
                         \n\nAre you sure you want to continue?\
                         \n\nNote: If what you wish is to update the link and keep the existing reviews just replace the existing link with a new one and we won't delete the reviews"
    if (isEmptyingFields && !confirm(confirmCopy)) {
      event.preventDefault()
      event.stopPropagation()
    }
  }
}
