import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="input-filler"
export default class extends Controller {
  static targets = ['sourceInput', 'targetInput']

  saveValue() {
    if (this.sourceInputTarget && this.targetInputTarget) {
      this.targetInputTarget.value = this.sourceInputTarget.innerText
    }
  }
}
