import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    waitTime: { type: Number, default: 1000 },
    transitionDuration: { type: Number, default: 140 },
    transition: { type: String, default: '-translate-x-full'}
  }

  connect() {
    this.timeouts = [window.setTimeout(() => this.dismiss(), this.waitTimeValue)]
  }

  disconnect() {
    this.timeouts.forEach((timeout) => window.clearTimeout(timeout))
  }

  dismiss() {
    this.element.classList.add(this.transitionValue)
    this.timeouts.push(window.setTimeout(() => this.element.remove(), this.transitionDurationValue))
  }
}
