import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="hide-panel"
export default class extends Controller {
  static targets = ["panel"]
  static classes = [ "hidden"]

  connect() {
    this.typingTimer = null
  }

  startedTyping(event) {
    console.log(event.currentTarget.innerText === "")
    if(event.currentTarget.innerText === "") {
      clearTimeout(this.typingTimer)
      this.stopedTyping()
    } else {
      this.panelTarget.classList.add(...this.hiddenClasses)

      clearTimeout(this.typingTimer)
      this.typingTimer = setTimeout(() => {
        this.stopedTyping()
      }, 800)
    }
  }

  stopedTyping() {
    this.panelTarget.classList.remove(...this.hiddenClasses)
  }
}
