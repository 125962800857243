import { Controller } from "@hotwired/stimulus"
import { toggle, leave } from "el-transition"

// Connects to data-controller="dropdown"
export default class extends Controller {
  static targets = [ "mobileMenu", "desktopMenu" ]

  disconnect() {
    clearTimeout(this.timeout)
  }

  toggleDesktopMenu() {
    toggle(this.desktopMenuTarget)
  }

  toggleMobileMenu() {
    toggle(this.mobileMenuTarget)
  }

  toggleDesktopMenuWithTimeout() {
    this.timeout = setTimeout(() => leave(this.desktopMenuTarget), 50)
  }

  toggleMobileMenuWithTimeout() {
    this.timeout = setTimeout(() => leave(this.mobileMenuTarget), 50)
  }
}
