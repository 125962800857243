import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-previewer"
export default class extends Controller {
  static targets = ['formPreview', 'thankYouPagePreview', 'button']

  connect() {
    if (this.hasFormPreviewTarget || this.hasThankYouPagePreviewTarget) {
      this.loadForm()
    }
  }

  preview() {
    if (this.hasButtonTarget) {
      this.buttonTarget.click()
    }
  }

  loadForm() {
    if (this.hasFormPreviewTarget) {
      clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        this.formPreviewTarget.click()
      }, 200)
    }
  }

  loadThankYouPage(){
    if (this.hasThankYouPagePreviewTarget) {
      clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {
        this.thankYouPagePreviewTarget.click()
      }, 200)
    }
  }
}
