import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="table-deleter"
export default class extends Controller {
  static targets = ["input", "normalActions", "afterSelectActions", "unitIdsInput"]
  static values = {
    unitIds: { type: Array, default: [] }
  }

  connect() {
  }

  checkAll(event) {
    if (this.hasInputTarget) {
      const expectedValue = event.currentTarget.checked

      this.inputTargets.forEach((input) => {
        if (expectedValue !== input.checked) {
          input.click()
        }
      })
    }
  }

  toggleUnitId(event) {
    const unitId = event.currentTarget.value
    const shouldAdd = event.currentTarget.checked

    if(shouldAdd) {
      this.unitIdsValue = [...this.unitIdsValue, unitId]
    } else {
      this.unitIdsValue = this.unitIdsValue.filter(id => id !== unitId)
    }
  }

  unitIdsValueChanged() {
    if (this.unitIdsValue.length > 0) {
      this.afterSelectActionsTarget.classList.remove('hidden')
      this.normalActionsTarget.classList.add('hidden')
    } else {
      this.afterSelectActionsTarget.classList.add('hidden')
      this.normalActionsTarget.classList.remove('hidden')
    }

    if (this.hasUnitIdsInputTarget) {
      this.unitIdsInputTarget.value = JSON.stringify(this.unitIdsValue)
    }
  }
}
