import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filters"
export default class extends Controller {
  static values = { opened: { type: Boolean, default: false } }
  static targets = [ 'filtersSvg', 'closeSvg']
  static classes = [ 'visible', 'hidden']

  toggle() {
    this.openedValue = !this.openedValue

    if(this.openedValue == true) {
      this.filtersSvgTarget.classList.remove(...this.visibleClasses)
      this.closeSvgTarget.classList.remove(...this.hiddenClasses)
      this.filtersSvgTarget.classList.add(...this.hiddenClasses)
      this.closeSvgTarget.classList.add(...this.visibleClasses)
    } else {
      this.closeSvgTarget.classList.remove(...this.visibleClasses)
      this.filtersSvgTarget.classList.remove(...this.hiddenClasses)
      this.closeSvgTarget.classList.add(...this.hiddenClasses)
      this.filtersSvgTarget.classList.add(...this.visibleClasses)
    }

  }
}
