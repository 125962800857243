import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "source" ]

  connect() {
    if (document.queryCommandSupported("copy")) {
      this.element.classList.add("clipboard--supported")
    }
  }

  copy(event) {
    this.sourceTarget.select()
    document.execCommand("copy")
    this.showCopiedText(event.currentTarget)

  }

  showCopiedText(pressedBtn) {
    const code = this.sourceTarget.value
    this.sourceTarget.value = "Copied!"
    pressedBtn.disabled = true
    this.sourceTarget.disabled = true
    setTimeout(() => this.reestablishOriginalValue(code, pressedBtn), 1000)
  }

  reestablishOriginalValue(originalValue, pressedBtn) {
    this.sourceTarget.value = originalValue
    this.sourceTarget.disabled = false
    pressedBtn.disabled = false
  }
}
