import { Controller } from "@hotwired/stimulus"
import Coloris from "@melloware/coloris";
import "@melloware/coloris/dist/coloris.css"

// Connects to data-controller="color-picker"
export default class extends Controller {
  static targets = ['input']
  static values = {
    withinTurboFrame: { type: Boolean, default: true }
  }

  connect() {
    if (this.withinTurboFrameValue === true) {
      return this.connectForTurboFrame()
    }

    Coloris.init()
    this.connectTargets()
  }

  connectForTurboFrame() {
    const $turboFrame = document.querySelector(`#guest_reviews_appearance_tab`)

    if($turboFrame.dataset.colorisInitialized === 'false') {
      Coloris.init()
      $turboFrame.dataset.colorisInitialized = 'true'
    }

    this.connectTargets()
  }

  connectTargets() {
    if(this.hasInputTarget) {
      this.inputTargets.forEach(input => Coloris({ el: `#${input.id}` }) )
    }
  }
}
