import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="tabs"
export default class extends Controller {
  static classes = ["active", "inactive"]
  static targets = ["tab"]
  static values = { activeTab: { type: Number, default: 0 }, formId: String }

  connect() {
    if (this.hasTabTarget) {
      this.setActiveTab()
    }
  }

  changeTab(event) {
    this.activeTabValue = parseInt(event.currentTarget.dataset.tabsActiveTabValue, 10)
  }

  activeTabValueChanged() {
    this.setActiveTab()
  }

  setActiveTab() {
    this.pageUrl = new URL(window.location)

    this.tabTargets.forEach((tab, index) => {
      if (index === this.activeTabValue) {
        tab.classList.remove(...this.inactiveClasses)
        tab.classList.add(...this.activeClasses)
        this.pageUrl.searchParams.set('tab', index)
        history.pushState({}, null,  this.pageUrl)
        if (this.hasFormIdValue) {
          const form = document.getElementById(this.formIdValue)
          form.querySelector('#search_tab').value = index
        }
      } else {
        tab.classList.remove(...this.activeClasses)
        tab.classList.add(...this.inactiveClasses)
      }
    })
  }
}
