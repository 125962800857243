import { Controller } from "@hotwired/stimulus"
import { Sortable, MultiDrag } from 'sortablejs'
import { patch } from '@rails/request.js'

Sortable.mount(new MultiDrag())

// Connects to data-controller="sortable"
export default class extends Controller {
  connect() {
    this.sortable = Sortable.create(this.element, {
      group: 'shared',
      onEnd: this.saveChanges.bind(this),
      animation: 150,
      ghostClass: '!bg-gray-200',
      sort: false,
      multiDrag: true,
      selectedClass: "!border-indigo-500",
    })
  }

  saveChanges(event) {
    const units = [...new Set([event.item, ...event.items])]
    const nodeId = event.to.dataset.sortableNodeIdValue

    units.forEach(unit => {
      if (unit.dataset.sortableCurrentNodeIdValue !== nodeId) {
        this.updateUnitNode(unit.dataset.sortableUpdateUrlValue, nodeId)
      }
    })
  }

  async updateUnitNode(updateUrl, nodeId) {
    await patch(updateUrl, {
      contentType: "application/json",
      responseKind: "json",
      body: { node_id: nodeId }
    })
  }
}
