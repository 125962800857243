import { Controller } from "@hotwired/stimulus"
import SlimSelect from 'slim-select'
import '../../../node_modules/slim-select/dist/slimselect.min.css'

export default class extends Controller {
  static values = { allowDeselect: { type: Boolean, default: true } }
  static targets = ['input']
  static classes = ['open']

  disconnect() {
    if (this.slimSelect) {
      this.slimSelect.destroy()
    }
  }

  connect() {
    if (!this.choices) {
      this.choices = this.initChoices()
    }
  }

  initChoices() {
    this.options = {
      select: this.inputTarget,
      beforeOpen: () => this.element.classList.add(...this.openClasses),
      beforeClose: () => this.element.classList.remove(...this.openClasses),
    }

    if ('choicesPlaceholder' in this.inputTarget.dataset) {
      this.options.placeholder = this.inputTarget.dataset.choicesPlaceholder
    }

    if (this.allowDeselectValue) {
      this.options = {
        ...this.options,
        allowDeselect: true,
        deselectLabel: this.deselectLabel()
      }
    }

    this.slimSelect = new SlimSelect(this.options)
  }

  toggle() {
    this.slimSelect.open()
  }

  deselectLabel() {
    const multipleSvg = `<svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 stroke-white-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                         </svg>`
    const singleSvg = `<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 stroke-white-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M3 12l6.414 6.414a2 2 0 001.414.586H19a2 2 0 002-2V7a2 2 0 00-2-2h-8.172a2 2 0 00-1.414.586L3 12z" />
                      </svg>`

    return this.inputTarget.hasAttribute('multiple') ? multipleSvg : singleSvg
  }
}
